import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import NumberFormat from 'react-number-format'

import { container, padding, bgImage, bgIcon, hoverState } from '../../styles/global'
import { media } from '../../styles/utils'

import { resolvePrice, resolveArtist, getNumberScaleProps } from '../../utils/shopify'

const GridItem = props => {
    const { item } = props
    const image = item?.images[0]?.originalSrc || item?.images[0]?.src
    const hoverImage = item?.images[1]?.originalSrc || item?.images[1]?.src
    const artist = resolveArtist(item)
    const price = resolvePrice(item)

    const renderImage = (image, hoverImage) => {
        return (
            <Image
                key={image}
                src={image}
            >
                {(src, loading) => {
                    return (
                        <LoadedImage
                            className={hoverImage && 'hover-image'}
                            src={src}
                            style={{ opacity: loading || hoverImage ? 0 : 1 }}
                        />
                    )
                }}
            </Image>
        )
    }

    return (
        <Product>
            <ImageWrapper
                className={'image-wrapper'}
                style={{ backgroundColor: !item.images && '#C4C4C4' }}
                to={`/product/${item.handle}`}
            >
                {image && renderImage(image)}
                {hoverImage && renderImage(hoverImage, true)}
            </ImageWrapper>
            <Info>
                {artist && <Heading>{artist}</Heading>}
                {item.title && <Subheading to={`/product/${item.handle}`}>{item.title}</Subheading>}
                {price && (
                    <Description>
                        <NumberFormat
                            value={price}
                            prefix={'$'} 
                            displayType={'text'} 
                            {...getNumberScaleProps()}
                        />
                    </Description>
                )}
            </Info>
        </Product>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled(Link)``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

// Product

const ImageWrapper = styled(Link)``


const Product = styled.div`
    position: relative;

    ${ImageWrapper} {
        position: relative;
        display: flex;
        width: 100%;
        /* height: 290px; */
        padding-top: 100%;
        cursor: pointer;

        ${media.phone`
            height: 0;
            padding-top: 100%;
        `}

        ${LoadedImage} {
            position: absolute;
            top: 0;
            left: 0;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            object-position: top left;

            &.hover-image {
                z-index: 2;
                opacity: 0;
            }
        }
    }

    ${Info} {
        margin-top: 12px;

        &, * {
            font-family: 'Courier';
            font-weight: 400;
            font-size: 16px;
            line-height: 131%;
            color: black;

            ${media.phone`
                font-size: 12px;
                line-height: 14px;
            `}
        }

        /* Artist */
        ${Heading} {
            text-transform: uppercase;
        }

        /* Product Title */
        ${Subheading} {}

        /* Price */
        ${Description} {
            &, * {
                color: #C4C4C4;
            }
        }
    }

    &:hover {
        .hover-image {
            opacity: 1 !important;
        }
    }
`

export default GridItem