import { css } from 'styled-components'

const type = {
    heading1: css`

    `,
    heading2: css`

    `,
    heading3: css`

    `,
    heading4: css`

    `,
    body: css`
        font-size: 12px;
        line-height: 18px;
    `,
}

export default type