import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { SignupForm } from '../../components'

import { container, padding, bgImage } from '../../styles/global'
import { media } from '../../styles/utils'
import { parseACF, resolveSocialIcon } from '../../utils'

import logo from '../../assets/images/bsr-logo-with-text.svg'

const Footer = (props) => {
    const data = useStaticQuery(query)?.allWordpressGlobal?.nodes[0]?.footer
    // console.log(data)

    const renderNav = () => {
        const menuItems = [
            {
                title: 'Artists',
                link: '/artists',
            },
            {
                title: 'Vinyl',
                link: '/shop?filter=vinyl',
            },
            {
                title: 'Merch',
                link: `/shop?filter=merch`,
            },
        ]

        const items = menuItems.map((item, i) => {
            return (
                <NavItem key={i} as={Link} to={item.link}>
                    {item.title}
                </NavItem>
            )
        })

        return (
            <Nav>
                <Heading>
                    <NavItem as={Link} to={`/shop`}>
                        Shop
                    </NavItem>
                </Heading>

                {items}
            </Nav>
        )
    }

    const renderInfo = () => {
        return (
            <Info>
                <Heading>Bedroom Suck</Heading>
                <Description
                    dangerouslySetInnerHTML={{
                        __html: data?.footer_address,
                    }}
                />
            </Info>
        )
    }

    const renderTop = () => {
        return (
            <Top>
                {renderNav()}
                {renderInfo()}
            </Top>
        )
    }

    const renderSocialLinks = () => {
        if (!data.footer_social_links) return
        const items = data.footer_social_links.map((item, i) => {
            return (
                <ExternalLink key={i} href={item?.link} target={'_blank'}>
                    <Icon src={resolveSocialIcon(item.type)} />
                </ExternalLink>
            )
        })

        return <SocialLinks>{items}</SocialLinks>
    }

    const renderMiddle = () => {
        return (
            <Middle>
                <SiteCredit>
                    Website designed & developed by{' '}
                    <ExternalLink
                        href={'https://morebymore.com/'}
                        target={'_blank'}
                    >
                        More Studio
                    </ExternalLink>
                </SiteCredit>
                {renderSocialLinks()}

                <Tagline
                    dangerouslySetInnerHTML={{
                        __html: data?.footer_tagline,
                    }}
                />
            </Middle>
        )
    }

    const renderBottom = () => {
        return (
            <Bottom>
                <Left>
                    <Acknowledgement
                        dangerouslySetInnerHTML={{
                            __html: data.footer_first_nations,
                        }}
                    />
                </Left>

                <Right>
                    <Logo
                        as={Link}
                        to={`/`}
                    >
                        <Icon src={logo} />
                    </Logo>
                </Right>
            </Bottom>
        )
    }

    return (
        <Wrapper>
            <Container>
                {renderTop()}
                {renderMiddle()}
                {renderBottom()}
            </Container>
        </Wrapper>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``
const ExternalLink = styled.a``
const Icon = styled.img``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: 1px solid #232323;
`

const Container = styled.div`
    ${container};
    ${padding};
`

// Top

const Nav = styled.div``
const NavItem = styled.div``

const Top = styled.div`
    display: flex;
    flex-flow: row wrap;

    ${Heading} {
        margin-bottom: 24px;

        &,
        * {
            font-family: Courier;
            font-weight: 400;
            font-size: 18px;
            line-height: 97.7%;
            color: black;
        }
    }

    ${Nav}, ${Info} {
        display: flex;
        flex-direction: column;
    }

    ${Nav} {
        margin-right: 180px;

        ${media.phone`
            margin-right: 24px;
        `}
    }

    ${Info} {
    }

    ${NavItem}, ${Description} {
        &,
        * {
            font-family: 'Neue Haas', sans-serif;
            font-weight: 400;
            font-size: 16.34px;
            line-height: 20px;
            color: black;
            margin: 0;
        }
    }
`

// Middle

const SiteCredit = styled.div``
const SocialLinks = styled.div``
const Tagline = styled.div``

const Middle = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-top: 312px;

    ${media.phone`
        margin-top: 234px;
    `}

    ${SiteCredit} {
        width: 100%;

        ${media.phone`
            max-width: 230px;
        `}

        &, * {
            font-family: Courier;
            font-style: 400;
            font-size: 18px;
            line-height: 1;

            ${media.phone`
                font-size: 14px;
            `}
        }

        ${ExternalLink} {
            color: black;
            text-decoration: underline;
        }
    }

    ${SocialLinks} {
        display: flex;
        justify-content: flex-start;
        margin-top: 34px;
        margin-right: 23px;

        ${ExternalLink} {
            position: relative;
            width: 50px;
            height: 50px;
            background: white;
            border: 1px dashed black;

            ${media.phone`
                width: 36px;
                height: 36px;
            `}

            &:not(:last-child) {
                margin-right: 9px;

                ${media.phone`
                    margin-right: 6px;
                `}
            }
        }

        ${Icon} {
            position: absolute;
            top: 10px;
            left: 10px;
            right: 10px;
            bottom: 10px;
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            object-fit: contain;
            object-position: center;

            ${media.phone`
                top: 5px;
                left: 5px;
                right: 5px;
                bottom: 5px;
                width: calc(100% - 10px);
                height: calc(100% - 10px);
            `}
        }
    }

    ${Tagline} {
        margin-top: 34px;
        font-family: 'Neue Haas', sans-serif;
        font-weight: 400;
        font-size: 48.8px;
        line-height: 1;

        ${media.phone`
            margin-top: 14px;
            font-size: 36px;
            line-height: 44px;
        `}
    }
`

// Bottom

const Acknowledgement = styled.div``
const Logo = styled.div``
const Left = styled.div``
const Right = styled.div``

const Bottom = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;

    ${Left} {
        flex: 1;
        max-width: 80%;

        ${media.phone`
            flex: none;
            width: 100%;
            max-width: initial;
        `}
    }

    ${Acknowledgement} {
        margin-top: 32px;

        ${media.phone`
            margin-top: 15px;
        `}

        &, * {
            font-family: Courier;
            font-weight: 400;
            font-size: 18px;
            line-height: 97.7%;

            ${media.phone`
                font-size: 14px;
            `}
        }
    }

    ${Right} {
        width: 84px;
        flex-shrink: 0;
        flex-grow: 0;

        ${media.phone`
            margin-top: 32px;
        `}

        ${Logo}, ${Icon} {
            width: 100%;
        }

        ${Icon} {
            height: auto;
        }
    }
`

const query = graphql`
    query {
        allWordpressGlobal {
            nodes {
                footer {
                    footer_address
                    footer_first_nations
                    footer_social_links {
                        link
                        type
                    }
                    footer_tagline
                }
            }
        }
    }
`

export default Footer
