import React, { useState, useContext, Component, useRef } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import styled, { css, createGlobalStyle } from 'styled-components'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Form from 'react-dynamic-form'
import { email } from '../../utils/validators'

import { media } from '../../styles/utils'
import { container, padding } from '../../styles/global'

const SignupForm = (props) => {
    const signupFormRef = useRef(null)
    const [submitting, setSubmitting] = useState(false)
    const [formComplete, setFormComplete] = useState(false)
    const [error, setError] = useState(false)

    const handleSubmit = (fields, resetForm) => {
        const { formComplete } = fields

        setSubmitting(true)
        setError(false)

        // make api call

        let data = {
			email: fields['email'].value,
        }

        addToMailchimp(data.email).then(mc_resp => {
            if (mc_resp.result == 'success') {
                setSubmitting(false)
                setFormComplete(true)
            } 

            if (mc_resp.result == 'error') {
                setError('Email address already subscribed')
                resetForm()
            }
        })     
    }

    return (
        <Wrapper
            noBorderTop={props.noBorderTop}
        >
            <FormStyles />

            <Heading>
                {!formComplete && `sign up for free shit, special events, etc`}
                {formComplete && `thanks for signing up!`}
            </Heading>

            <FormWrapper
                formComplete={formComplete}
            >
                <Form
                    ref={signupFormRef}
                    data={signupFields}
                    moveToInvalidField={false}
                    renderSubmit={false}
                    onSubmit={(fields, resetForm) =>
                        handleSubmit(fields, resetForm)
                    }
                />

                {error && (
                    <Error>error</Error>
                )}

                <button
                    disabled={submitting ? true : false}
                    onClick={() => {
                        signupFormRef.current &&
                            signupFormRef.current.handleSubmit()
                    }}
                >
                    Submit
                </button>
            </FormWrapper>
        </Wrapper>
    )
}

const Heading = styled.div``

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 66px;
    padding-bottom: 63px;
    ${padding};
    border-top: 5px solid #232323;

    ${Heading} {
        font-family: Courier;
        font-weight: 400;
        font-size: 18px;
        line-height: 1;
    }

    ${props => {
        if (props.noBorderTop) return css`
            border-top: none;
        `
    }}
`

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;

    ${props => {
        if (props.formComplete)
            return css`
                display: none;
            `
    }}

    &,
    > div {
        width: 540px;
        max-width: 100%;
    }

    input {
        width: 100%;
        height: 60px;
        padding: 4px;
        border-radius: 0;
        border: 1px dashed black;
        appearance: none;
        box-shadow: none;
        text-align: center;

        &,
        &::placeholder {
            color: black;
            opacity: 1;
        }

        &:focus {
            outline: none;
        }

        ${(props) => {
            if (props.disabled)
                return css`
                    pointer-events: none;
                `
        }}
    }

    button {
        border: none;
        appearance: none;
        box-shadow: none;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 125px;
        max-width: 100%;
        height: 60px;
        margin-top: 47px;
        padding: 8px;
        background-color: black;
        border: 1px dashed white;
        border-radius: 0;

        font-family: Courier;
        font-weight: 400;
        font-size: 22.5px;
        line-height: 97.7%;
        color: white;
        cursor: pointer;

        ${media.phone`
            font-size: 16px;
        `}

        &:focus {
            outline: none;
        }
    }
`

const FormStyles = createGlobalStyle`
    .dynamic-fields {}

    .dynamic-field {
        &, * {
            font-family: 'Neue Haas', sans-serif;
            font-weight: 400;
            font-size: 18px;
            line-height: 1;
        }

        /* error message */
        > *:nth-child(2) {
            margin-top: 12px;
            font-size: 14px;
        }
    }
`

const Error = styled.div`
    margin-top: 12px;
    font-size: 14px;
    color: red;
`

class Input extends Component {
    onChange = (e) => {
        const { onChangeValue, validator } = this.props
        const value = e.target.value

        if (onChangeValue) {
            onChangeValue(value, validator)
        }
    }

    render() {
        return <input onChange={this.onChange} placeholder={this?.props?.placeholder} />
    }
}

export const signupFields = {
    fields: [
        {
            type: 'email',
            name: 'email',
            validator: email,
            errorMessage: 'Please enter a valid email address',
            placeholder: 'Enter email address here',
            component: ({ value, placeholder, onChangeValue, validator }) => {
                return (
                    <Input
                        value={value}
                        placeholder={placeholder}
                        onChangeValue={onChangeValue}
                        validator={email}
                    />
                )
            },
        },
    ],
}

export default SignupForm
