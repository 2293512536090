import React, { useState, useEffect, useContext } from 'react'
import styled, { css, createGlobalStyle } from 'styled-components'
import { Link } from 'gatsby'
import { find, filter, forEach, isEqual, remove } from 'lodash'
import { useMount } from 'react-use'
import Select from 'react-select'
import NumberFormat from 'react-number-format'

import { media } from '../../styles/utils'
import { container, padding, hoverState, bgIcon, button } from '../../styles/global'
import { filterVariantsByOption } from './utils'
import { getGlobalData } from '../../utils'
import { cartContext } from '../Cart/CartProvider'
import { getNumberScaleProps } from '../../utils/shopify'

const ProductForm = (props) => {
    const { productData } = props
    if (!productData) return

    const {
        cartItems,
        setCartItems,
        addCartItem,
        active: cartActive,
        setActive: setCartActive,
    } = useContext(cartContext)

    const [selectedOptions, setSelectedOptions] = useState({})
    const [availableVariants, setAvailableVariants] = useState({})
    const [selectedVariant, setSelectedVariant] = useState(false)
    const variants = productData?.variants
    const options = productData?.options
    
    useEffect(() => {
        // set selected variant
        if (availableVariants.length === 1) {
            setSelectedVariant({
                ...productData,
                selectedVariant: availableVariants[0],
            })
        } else {
            setSelectedVariant(false)
        }
    }, [availableVariants])

    useEffect(() => {
        let matches = variants

        forEach(selectedOptions, (value, key) => {
            matches = filter(matches, (variant) => {
                const matchOption = find(variant.selectedOptions, {
                    name: key,
                    value: value,
                })

                if (matchOption) return variant
            })
        })

        // only one available variant, so set the selected options to match that
        if (matches.length === 1) {
            let theseOptions = {}
            forEach(matches[0]?.selectedOptions, (option, key) => {
                theseOptions[option.name] = option.value
            })

            if (!isEqual(selectedOptions, theseOptions)) {
                setSelectedOptions({
                    ...theseOptions,
                })
            }
        }

        setAvailableVariants(matches)
    }, [selectedOptions])

    const checkDisabled = (option) => {
        // Filter variants by passed option
        const matches = filterVariantsByOption(availableVariants, option)

        if (!matches.length) return true
    }

    const checkSelected = (option) => {
        let match = false

        for (const key in selectedOptions) {
            if (selectedOptions.hasOwnProperty(key)) {
                if (
                    key === option.name &&
                    selectedOptions[key] === option.value
                ) {
                    match = true
                }
            }
        }

        return match
    }

    const handleSelectChange = (optionName, value) => {
        const option = {
            name: optionName,
            value: value,
        }

        // Filter variants by passed option
        const matches = filterVariantsByOption(availableVariants, option)

        if (matches.length) {
            setSelectedOptions({
                ...selectedOptions,
                [option.name]: option.value,
            })
        } else {
            setSelectedOptions({
                [option.name]: option.value,
            })
        }
    }

    const renderOptions = () => {
        const items = options.map((option, i) => {
            let selectOptions = []
            let selectedOption;

            option.values.forEach((option) => {
                selectOptions.push({
                    value: option.value,
                    label: option.value,
                })
            })
            
            if (selectedOptions[option.name]) {
                selectedOption = {
                    value: selectedOptions[option.name],
                    label: selectedOptions[option.name],
                }
            }

            if (selectedOptions[option.name] == 'Default Title') return

            return (
                <Select
                    key={i}
                    options={selectOptions}
                    value={selectedOption}
                    onChange={(item) =>
                        handleSelectChange(option.name, item.value)
                    }
                    placeholder={`Select a ${option.name}`}
                    isSearchable={false}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            primary: 'white',
                            primary25: 'white',
                            primary50: 'white',
                            primary75: 'white',
                        },
                    })}
                />
            )
        })

        return <Options>{items}</Options>
    }

    const renderPrice = () => {
        let price

        if (!selectedVariant) {
            price = variants[0]?.price?.amount
        } else {
            price = selectedVariant?.selectedVariant?.price?.amount
        }

        return (
            <Price>
                <NumberFormat
                    value={price}
                    prefix={'$'} 
                    displayType={'text'} 
                    {...getNumberScaleProps()}
                />
            </Price>
        )
    }

    const renderAddToCart = () => {
        return (
            <AddToCart>
                <Button
                    className={'button add-to-cart'}
                    onClick={() => addCartItem(selectedVariant)}
                    disabled={!selectedVariant || selectedVariant?.selectedVariant && !selectedVariant?.selectedVariant?.available}
                >
                    {selectedVariant?.selectedVariant && !selectedVariant?.selectedVariant?.available ? 'Sold Out' : 'Add to Cart'}
                </Button>
            </AddToCart>
        )
    }

    return (
        <>
            <SelectStyles />
            {options && renderOptions()}
            {renderPrice()}
            {renderAddToCart()}
        </>
    )
}

// Shared

const Heading = styled.h2``
const Title = styled.div``
const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

// Layout

const Container = styled.div``

const Wrapper = styled.div``

// Options

const Options = styled.div`
    margin-top: 14px;
`

// Select styles

const SelectStyles = createGlobalStyle`
    .react-select-container {
        margin-top: 10px;

        &, * {
            font-family: Courier;
            font-weight: normal;
            font-size: 16px;
            line-height: 1;
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 28px;
            transform: translateY(-50%);
            height: 14px;
            width: 14px;
            ${bgIcon};
            background-image: url(${require('../../assets/icons/select-arrow.svg')});
        }

        &, .react-select__control {
            height: 52px;
        }
        
        .react-select__control {
            border-radius: 7px;
            border: 2px dotted black;
            border-color: black!important;
        }

        .react-select__menu {
            background: white;
            border: 2px dotted black;
        }

        .react-select__indicator {
            display: none;
        }

        .react-select__menu-list {
            padding: 0;
        }

        .react-select__option {
            height: 52px;
            display: flex;
            align-items: center;
            margin: 0;
            cursor: pointer;

            &:not(:last-child) {
                border-bottom: 2px dotted black;
            }
        }
    }
`

// Price

const Price = styled.div`
    margin-top: 14px;

    &, * {
        font-family: 'Neue Haas', sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
    }
`

// Buttons (Add to Cart)

const Icon = styled.img``
const Button = styled.div``
const AddToCart = styled.div`
    width: 100%;
    margin-top: 15px;

    ${Button} {
        ${button}
    }
`

export default ProductForm
