import fonts from './src/styles/fonts.css'
import wrapWithProvider from "./wrap-with-provider"

export const wrapRootElement = wrapWithProvider

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    const { pathname } = location

    setTimeout(() => {
        window.scrollTo(0, 0)
    }, 0);

    return false
}