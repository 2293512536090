import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { forEach } from 'lodash'
import 'react-responsive-audio-player/dist/audioplayer.css'
 
import { container, padding, bgImage, bgIcon } from '../../styles/global'
import { media, isClient } from '../../styles/utils'
import { parseACF, resolveSocialIcon } from '../../utils'
import { useMount } from 'react-use'

// Audio Player import 

let AudioPlayer

if (isClient()) {
    AudioPlayer = require('react-responsive-audio-player')
}

const PlaylistPlayer = (props) => {
    const data = useStaticQuery(query)?.allWordpressGlobal?.nodes[0]?.footer
    const [playlist, setPlaylist] = useState(false)

    useMount(() => {
        if (data?.playlist?.length) {
            let songs = []

            forEach(data.playlist, (song, i) => {
                songs.push({
                    displayText: song.title,
                    url: song.mp3_file
                })
            })

            setPlaylist(songs)
        }
    })

    const renderPlayer = () => {
        if (!playlist) return;

        return (
            <AudioPlayer 
                playlist={playlist} 
                hideBackSkip={true}
                autoplay={false}
            />
        )   
    }

    return (
        <Wrapper>
            <Container>
                {renderPlayer()}
            </Container>
        </Wrapper>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``

const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
    background: #A6E4FF;
	height: 42px;
	box-shadow: 1px 3px 10px 3px rgba(0, 0, 0, 0.25);
	width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    ${media.phone`
        display: none;
    `}

    .audio_player {
        background-color: transparent;
        height: 42px;

        .play_pause_button,
        .skip_button {
            .triangle_1,
            .triangle_2,
            .right_facing_triangle,
            .left,
            .right {
                display: none;
                background-color: #A6E4FF;
            } 
        }

        .play_pause_button {         
            padding: 0;
            margin: 0;
            margin-right: 18px;
            ${bgImage};

            width: 43px;
            height: 22px;
            background-image: url(${require('../../assets/icons/player-pause.svg')});
           
            &.paused {
                width: 43px;
                height: 22px;
                background-image: url(${require('../../assets/icons/player-play.svg')});
            }
        }

        .skip_button {
            position: relative;
            padding: 0;
            margin: 0;

            width: 55px;
            height: 22px;
            background-image: url(${require('../../assets/icons/player-skip.svg')});
            ${bgImage};
        }

        .audio_progress_overlay {
            justify-content: flex-start;
            align-items: center;
            padding-left: 10px;

            .audio_info_marquee {
                /* width: auto;
                margin-right: 60px; */
                
                .audio_info {
                    font-family: 'Neue Haas', sans-serif;
                    font-size: 16.34px;
                    line-height: 20px;
                    color: black;
                }
            }
        }

        .audio_time_progress {
            color: black;
            font-family: Courier;
            font-size: 18px;
        }

        .audio_progress_container {
            pointer-events: none;

            .audio_progress {
                display: none;
            }
        }
    }
`

const Container = styled.div`
    ${container};
    ${padding};
    position: relative;
    height: 100%;
`

const query = graphql`
    query {
        allWordpressGlobal {
            nodes {
                footer {
                    footer_address
                    footer_first_nations
                    footer_social_links {
                        link
                        type
                    }
                    footer_tagline
                    playlist {
                        title
                        mp3_file
                    }
                }
            }
        }
    }
`

export default PlaylistPlayer
