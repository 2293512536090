import React, { useState, useContext } from 'react'
import { navigate, Link } from 'gatsby'
import { useWindowScroll } from 'react-use';
import styled, { css } from 'styled-components'
import Headroom from 'react-headroom'
import { padStart } from 'lodash';

import { PlaylistPlayer } from '../'

import { media } from '../../styles/utils'
import { container, bgImage, bgIcon, padding, hoverState } from '../../styles/global'

import kangaroo from '../../assets/images/logo.svg'
import cartMobile from '../../assets/icons/cart-mobile.svg'

import { cartContext } from '../Cart/CartProvider'
import { getCartQuantity } from '../Cart/utils';

const Header = (props) => {
	const {x, y} = useWindowScroll()
	const { active: cartActive, setActive: setCartActive, cartItems } = useContext(cartContext)

	const renderCart = () => {
		const cartQuantity = getCartQuantity(cartItems)
		const cartQuantityLabel = cartQuantity ? padStart(cartQuantity, 2, '0') : 'XX'

		return (
			<Cart 
				onClick={() => setCartActive(!cartActive)}
			>
				<Spacer>
					Cart {cartQuantityLabel}
				</Spacer>

				<Heading>
					Cart {cartQuantityLabel}
				</Heading>

				<Icon src={cartMobile} />
			</Cart>
		)
	}

	const renderNav = () => {
		const menuItems = [
			{
				title: 'Vinyl',
				link: '/shop?filter=vinyl',
			},
			{
				title: 'Merch',
				link: `/shop?filter=merch`,
			},
			{
				title: 'Artists',
				link: '/artists',
			},
		]

		const items = menuItems.map((item, i) => {
			return (
				<NavItem
					key={i}
					as={Link}
					to={item.link}
				>
					<Spacer>
						{item.title}
					</Spacer>

					<Heading>
						{item.title}
					</Heading>
				</NavItem>
			)
		})

		return (
			<Nav>
				{items}
				{renderCart()}
			</Nav>
		)
	}

	return (
		<HeadroomWrapper 
			style={{ zIndex: 9 }} 
			className={'header'}
		>
			<Wrapper>
				<PlaylistPlayer/>

				<Container>
					<Top>
						<Heading 
							as={Link} 
							to={`/`}
						>
							Bedroom Suck Records
						</Heading>
						<BGIcon 
							as={Link}
							to={`/`}
							image={kangaroo} 
						/>
					</Top>

					{renderNav()}
				</Container>
			</Wrapper>	
		</HeadroomWrapper>
	)
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``
const Icon = styled.img``

const Image = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
`

const BGIcon = styled.div`
	background-image: url(${props => props.image});  
	${bgIcon};
	transition: opacity 0.45s ease;
`


// Layout

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white;
	box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
` 

const Container = styled.div`
	${container}
	${padding}
	flex-direction: column;
	position: relative;
	padding-top: 10px;
	padding-bottom: 17px;

	${media.phone`
		padding-bottom: 8px;
	`}
`

// Top (Site title and logo)

const Logo = styled.div``

const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: stretch;

	${Heading} {
		font-size: 86px;
		line-height: 1em;
		color: black;

		${media.desktopSmall`
			font-size: 50px;
		`}

		${media.phone`
			font-size: 31.2148px;
			margin-bottom: 6px;
		`}
	}

	${BGIcon} {
		flex: 1;
		background-position: right;
		margin-right: 18px;

		${media.phone`
			display: none;
		`}
	}
`


// Nav 
 
const Nav = styled.div`
	display: flex;
`
 
// Nav Item

const Spacer = styled.div``

const navItem = css`
	margin-top: 10px;
	padding: 7px 20px;
	border: 1px dashed #000000;
	box-sizing: border-box;
	position: relative;
	cursor: pointer;

	${media.phone`
		padding: 2px 5px;
		margin-top: 0;
	`}

	> * {
		font-size: 86px;
		line-height: 1em;
		color: black;

		${media.desktopSmall`
			font-size: 50px;
		`}

		${media.phone`
			font-size: 28px;
			font-size: 31.2148px;
		`}
	}

	${Spacer} {
		visibility: hidden;
	}

	${Heading} {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		white-space: nowrap;
	}

	&:hover {
		background: black;
		
		${Heading} {
			color: white;
			font-style: italic;
		}
	}
`

const NavItem = styled.div`
	${navItem}

	&:not(:last-child) {
		margin-right: 23px;

		${media.phone`
			margin-right: 6px;
		`}
	}
`

// Cart

const Cart = styled.div`
	border-radius: 35px;
	margin-left: auto;
	${navItem}

	${media.phone`
		border-radius: 12px;
		margin-left: 0;
		display: flex;
		align-items: center;
	`}

	${Heading}, ${Spacer} {
		${media.phone`
			display: none;
		`}
	}

	${Icon} {
		display: none;

		${media.phone`
			display: flex;
		`}
	}
`

// Headroom

const HeadroomWrapper = styled(Headroom)`
	.headroom--scrolled {
		${Top} {
			height: 0;
			opacity: 0;
		}	
	}
`

export default Header
