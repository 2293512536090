import { SHOPIFY_ACCESS_TOKEN, GATSBY_SHOPIFY_DOMAIN } from '../constants'
import Client from 'shopify-buy'
import { forEach, find, isEqual, uniq } from 'lodash'

export const client = Client.buildClient({
    storefrontAccessToken: SHOPIFY_ACCESS_TOKEN,
    domain: GATSBY_SHOPIFY_DOMAIN,
})

export const getParentCategory = (hover_menu, location) => {
    const { links: shop_hierarchy } = find(hover_menu, function (o) {
        return o.name === 'shop'
    })

    let match = false

    shop_hierarchy.forEach((category) => {
        const links = category?.links
        if (links) {
            links.forEach((link) => {
                if (isEqual(link.link, location.pathname)) {
                    match = category
                }
            })
        }
    })

    return match
}

export const getProductTags = (products) => {
    if (!products) return

    let tags = []

    forEach(products, (product, i) => {
        if (!product.tags) return

        forEach(product.tags, (tag) => {
            const match = tag.match(/type[^(]*\(([^)]*)\)/)
            if (match?.[1]) tags.push(match?.[1].toLowerCase())
        })
    })

    return uniq(tags)
}

export const getProductTypes = (products) => {
    if (!products) return

    let types = []

    forEach(products, (product, i) => {
        if (!product?.productType) return

        types.push(product?.productType)
    })

    types = types.sort((a, b) => {
        if (a.startsWith('vinyl')) return -1
        if (b.startsWith('vinyl')) return 1
        return 0
    })

    return uniq(types)
}


// Price number format

export const getNumberScaleProps = () => {
    return {
        displayType: 'text',
        thousandSeparator: true,
        fixedDecimalScale: true,
        decimalScale: 2,
    }
}

// get Price for cart item

export const getPriceProps = (price, exGST) => {
    return {
        value: exGST ? price.value : parseFloat(price.value) * 1.1,
        prefix: price.minPrice !== price.maxPrice ? 'From $' : '$',
    }
}

// get price for shop item (no selected variant)

export const resolvePrice = (item) => {
    let price

    if (item.priceRange) {
        price = item.priceRange?.minVariantPrice?.amount
    } else if (item.variants) {
        price = item.variants[0]?.price?.amount
    }

    return price
}

// Artist tag

export const resolveArtist = (item) => {
    let artist
    let match

    if (!item.tags) return

    forEach(item.tags, (tag) => {
        match = tag.match(/artist[^(]*\(([^)]*)\)/)
        if (match?.[1]) artist = match?.[1]
    })

    if (typeof artist === 'undefined') {
        forEach(item.tags, (tag) => {
            match = tag.match(/artist:(.*)/)
            if (match?.[1]) artist = match?.[1]
        })
    }

    return artist
}
