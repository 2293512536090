import { getPriceProps } from "../../utils/shopify";
import { forEach, filter } from 'lodash'

export const getCartQuantity = (cart) => {
    let quantity = 0;

    if (cart && cart.length > 0) {
        cart.forEach((elm, i) => {
            const cartItem = cart[i];
            quantity = quantity + cartItem.quantity
        });
    }

    return quantity
}

export const getCartTotal = (cartItems, exGST) => {
    let total = 0;

    if (cartItems && cartItems.length > 0) {
        cartItems.forEach((elm, i) => {
            const cartItem = cartItems[i]
            total = total + parseInt(cartItem.selectedVariant.price.amount * cartItem.quantity)
        });
    }

    return total
}

export const getCartCurrencyCode = (cartItems) => {
    let currencyCode = undefined;

    // get currency code from first cart item
    if (cartItems && cartItems.length > 0) {
        const cartItem = cartItems[0]
        currencyCode = cartItem.selectedVariant.price.currencyCode
    }

    return currencyCode
}

export const matchAttributes = (newItem, oldItem) => {
    if (!newItem.attributes) return true;

    const match = filter(newItem.attributes, (attr) => {
        let match;

        forEach(oldItem.attributes, (attribute) => {
            if (attribute.slug == attr.slug && attribute.tax == attr.tax) {
                match = true
            }
        })

        return match
    })

    return match.length == newItem.attributes.length
}

