import { find, filter } from 'lodash'

export const filterVariantsByOption = (availableVariants, option) => {
    const matches = filter(availableVariants, (variant) => {
        const matchOption = find(variant.selectedOptions, {
            name: option.name,
            value: option.value
        })

        if (matchOption) return variant
    })
    return matches
}
