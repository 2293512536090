import React, { useState, useEffect, useContext } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { Link, navigate } from 'gatsby'
import NumberFormat from 'react-number-format'
import FullHeight from 'react-div-100vh'
import { isEqual } from 'lodash'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { useMount, useUnmount } from 'react-use'
import { getCartTotal, getCartCurrencyCode, getCartQuantity } from './utils'

import { media, width } from '../../styles/utils'
import { container, bgImage, bgIcon, padding, hoverState, button, colours } from '../../styles/global'
import { getNumberScaleProps, getPriceProps } from '../../utils/shopify'
import { cartContext } from './CartProvider'

import cartClose from '../../assets/icons/cart-close.svg'
import cartRemove from '../../assets/icons/cart-remove.svg'

const Cart = (props) => {
    const { active, setActive, cartItems, setCartItems, updateQuantity, checkout } = useContext(cartContext)
    // console.log(cartItems)

    // useUnmount(() => {
    //     clearAllBodyScrollLocks()
    // })

    // useEffect(() => {
    //     if (active) {
    //         const cartEl = document.querySelector('#cart-wrapper')
    //         if (!cartEl) return
    //         disableBodyScroll(cartEl.current)
    //     } else {
    //         clearAllBodyScrollLocks()
    //     }
    // }, [active])

    const toggleCart = () => {
        setActive(!active)
    }

    const goToCheckout = () => {}

    const removeItemFromCart = (item) => {
        let updatedCartItems = []
        let removed = false

        cartItems.forEach((cartItem, index) => {
            if (isEqual(cartItem, item) && !removed) {
                removed = true
            } else {
                updatedCartItems.push(cartItem)
            }
        })

        setCartItems(updatedCartItems)
    }

    const renderCart = () => {
        const cartQuantity = getCartQuantity(cartItems)

        return (
            <>
                <Heading>{`${cartQuantity} ${
                    cartQuantity == 1 ? 'item' : 'items'
                } added to bag`}</Heading>
                {renderCartItems(
                    toggleCart,
                    cartItems,
                    removeItemFromCart,
                    updateQuantity
                )}
            </>
        )
    }

    const renderSubtotals = () => {
        return (
            <Subtotals>
                <Total>
                    Number of Items
                    <span>{getCartQuantity(cartItems)}</span>
                </Total>
                <Total>
                    Sub Total Amount
                    <Amount>
                        <NumberFormat
                            value={parseInt(getCartTotal(cartItems))}
                            prefix={'$'}
                            displayType={'text'}
                            {...getNumberScaleProps()}
                        />
                        
                        {cartItems && cartItems.length > 0 && (
                            <>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: '&nbsp;',
                                    }}
                                />
                                {getCartCurrencyCode(cartItems)}
                            </>
                        )}
                    </Amount>
                </Total>
            </Subtotals>
        )
    }

    return (
        <>
            <Wrapper active={active ? 'true' : 'false'} id={'cart-wrapper'}>
                <Container>
                    <Header>
                        <Back
                            onClick={() => {
                                navigate(`/shop`)
                                toggleCart()
                            }}
                        >
                            {`Return To Products`}
                        </Back>
                        <Label>{`Cart (${cartItems.length})`}</Label>
                        <Icon src={cartClose} onClick={toggleCart} />
                    </Header>

                    {renderCart()}

                    <Line />

                    <Footer>
                        {renderSubtotals()}

                        <Checkout
                            href={checkout?.webUrl}
                            target={'_blank'}
                            hasItems={
                                !checkout.processing &&
                                checkout?.webUrl &&
                                active &&
                                cartItems &&
                                cartItems.length > 0
                            }
                        >
                            {'Checkout'}
                        </Checkout>
                    </Footer>
                </Container>
            </Wrapper>

            <Background active={active} onClick={toggleCart} />
        </>
    )
}

export const renderCartItems = (
    toggleCart,
    cartItems,
    removeItemFromCart,
    updateQuantity
) => {
    let items
    // console.log(cartItems)

    const handleQtyChange = (values, item) => {
        if (values.floatValue) {
            updateQuantity(item, values.floatValue)
        }
    }

    const renderVariantOptions = (item) => {
        if (!item.selectedVariant.selectedOptions) return;
        
        const options = item.selectedVariant.selectedOptions.map((option, i) => {
            if (option.value == "Default Title") return;

            return (
                <Subheading 
                    key={i}
                >
                    {`${option.name}: ${option.value}`}
                </Subheading>
            )
        })

        return (
            <SelectedOptions>
                {options}
            </SelectedOptions>
        )
    }

    if (cartItems.length > 0) {
        items = cartItems.map((item, i) => {
            return (
                <CartItem key={i}>
                    <Link onClick={toggleCart} to={`/product/${item.handle}`}>
                        <Image image={item.selectedVariant.image.src} />
                    </Link>

                    <Info>
                        {/* Title */}
                        <Heading>
                            <Link
                                onClick={toggleCart}
                                to={`/product/${item.handle}`}
                            >
                                {item.title}
                            </Link>
                        </Heading>

                        {renderVariantOptions(item)}

                        {/* Subtotals */}

                        <Subtotals>
                            <Total>
                                <Label>Price</Label>
                                <NumberFormat
                                    value={parseInt(
                                        item.selectedVariant.price.amount
                                    )}
                                    prefix={'$'}
                                    displayType={'text'}
                                    {...getNumberScaleProps()}
                                />
                            </Total>

                            <Total>
                                <Label>Qty</Label>
                                <NumberFormat
                                    value={item.quantity}
                                    decimalScale={0}
                                    allowNegative={false}
                                    allowLeadingZeros={false}
                                    onValueChange={(values) =>
                                        handleQtyChange(values, item)
                                    }
                                />
                            </Total>

                            <Total>
                                <Label>Item Subtotal</Label>
                                <NumberFormat
                                    value={
                                        parseInt(
                                            item.selectedVariant.price.amount
                                        ) * item.quantity
                                    }
                                    prefix={'$'}
                                    displayType={'text'}
                                    {...getNumberScaleProps()}
                                />
                            </Total>
                        </Subtotals>
                    </Info>

                    {/* Remove from Cart */}
                    <Icon
                        src={cartRemove}
                        onClick={() => removeItemFromCart(item)}
                    />
                </CartItem>
            )
        })
    }

    return <CartItems>{items}</CartItems>
}

const Wrapper = styled(FullHeight)`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1 0 auto;
    width: 495px;
    height: 100vh;
    background: ${colours.offwhite};
    box-shadow: -10px 0px 10px rgba(0, 0, 0, 0.25);

    transition: transform 0.35s ease;
    transform: translateX(100%);
    pointer-events: none;
    opacity: 0;

    ${(props) => {
        if (props.active === 'true')
            return css`
                transform: translateX(0);
                pointer-events: all;
                opacity: 1;
            `
    }}

    ${media.tablet`
        transition: opacity 0.3s ease-in-out;
        transform: translateX(0);

        ${(props) => {
            if (props.active === 'true')
                return css`
                    transition: opacity 0.15s ease-in-out;
                `
        }}
    `}

    ${media.phone`
        left: 0;
        width: 100%;
        border-left: none;
        box-shadow: none;
        background: #F7FDFF;
    `}
`

// Shared

const Icon = styled.img``

const Line = styled.div`
    border-bottom: 1px solid black;
`

const Heading = styled.div``

const Image = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage}
`

const Subheading = styled.div``
const Description = styled.div``
const Label = styled.div``

const Panel = css`
    display: flex;
    justify-content: space-between;
`

const Total = styled.div``
const Amount = styled.div``
const Subtotals = styled.div``

// Container

const Container = styled.div`
    ${container}
    padding: 18px 24px 44px 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    ${media.phone`
        padding: 14px 18px 42px 24px;
    `}

    /* items added to bag */
    > ${Heading} {
        font-family: 'Neue Haas', sans-serif;
        font-weight: 400;
        font-size: 32.4px;
        line-height: 113.5%;

        ${media.phone`
            font-size: 24.5158px;
            line-height: 113.5%;
        `}
    }
`

// Background

const Background = styled.div`
    z-index: 10;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    transition: opacity 0.35s ease;

    ${(props) => {
        if (props.active)
            return css`
                pointer-events: all;
            `
    }}

    ${media.phone`
        display: none;
    `}
`

// Cart header

const Back = styled.div``
const Close = styled.div``
const Header = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 12px;

    ${media.phone`
        margin-bottom: 9px;
    `}

    ${Back} {
        cursor: pointer;
        font-family: 'Neue Haas', sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 113.5%;

        ${media.phone`
            font-size: 13.6199px;
            line-height: 113.5%;
        `}
    }

    ${Label} {
        font-family: 'Neue Haas', sans-serif;
        font-weight: 400;
        font-size: 24px;
        line-height: 97.7%;

        ${media.phone`
            font-size: 18.1599px;
            line-height: 97.7%;
        `}
    }

    /* close cart */
    ${Icon} {
        cursor: pointer;

        ${media.phone`
            width: 72px;
            height: 72px;
        `}
    }
`

// Cart Items

const CartItems = styled.div`
    flex: 1;
    margin-top: 22px;
    max-height: 100%;
    overflow-y: auto;

    ${media.phone`
        margin-top: 16px;
    `}

    /* Scroll Bar Overlap Fix */

    width: calc(100% + 60px);
    padding-right: 60px;
    box-sizing: border-box;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 60px;
        border-top: 1px solid black;
    }
`

// Cart Item

const Price = styled.div``
const Info = styled.div``
const Remove = styled.div``
const SelectedOptions = styled.div``
const CartItem = styled.div`
    display: flex;
    align-items: flex-start;
    position: relative;
    padding: 22px 0 38px;
    border-bottom: 1px solid black;

    ${media.phone`
        padding: 16px 0 28px;
    `}

    ${Image} {
        width: 131px;
        height: 131px;
        margin-right: 15px;

        ${media.phone`
            width: 100px;
            height: 100px;
            margin-right: 12px;
        `}
    }

    ${Info} {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-right: 8px;

        ${media.phone`
            margin-right: 4px;
        `}

        * {
            user-select: none;
            font-family: Courier;
            font-weight: 400;
        }

        ${Heading} {
            display: inline-block;
            &,
            * {
                font-size: 16px;
                line-height: 113.5%;
                color: black;

                ${media.phone`
                    font-size: 12px;
                `}
            }
        }

        ${SelectedOptions} {
            margin-top: 4px;

            ${Subheading} {
                font-size: 14px;
                color: #85868a;

                ${media.phone`
                    font-size: 11px;
                `}
            }
        }

        ${Subtotals} {
            margin-top: 27px;

            ${media.phone`
                margin-top: 17px;
            `}

            &,
            * {
                font-size: 14px;
                line-height: 180.5%;

                ${media.phone`
                    font-size: 11px;
                `}
            }

            ${Total} {
                display: flex;
                align-items: center;

                ${Label} {
                    margin-right: 20px;
                }

                input {
                    appearance: none;
                    width: 40px;
                    height: 20px;
                    padding-left: 8px;
                    border: 1px solid rgba(0, 0, 0, 0.5);
                    background-color: ${colours.offwhite};
                    border-radius: none !important;
                    outline: none !important;

                    ${media.phone`
                        width: 30px;
                        height: 16px;
                    `}
                }
            }
        }
    }

    /* remove cart item */
    ${Icon} {
        display: flex;
        margin-top: 2px;
        cursor: pointer;

        ${media.phone`
            width: 12px;
            height: 12px;
        `}
    }
`

// Cart footer

const Footer = styled.div`
    margin-top: 14px;

    ${Subtotals} {
        ${Total} {
            display: flex;
            justify-content: space-between;

            &,
            * {
                font-family: Courier;
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;

                ${media.phone`
                    font-size: 14px;
                    line-height: 16px;
                `}
            }

            &:not(:last-child) {
                margin-bottom: 9px;

                ${media.phone`
                    margin-bottom: 7px;
                `}
            }
        }
    }
`

// Checkout

const Checkout = styled.a`
    margin-top: 21px;
    ${button}
    font-weight: 400;

    ${media.phone`
        margin-top: 26px;
    `}

    pointer-events: ${(props) => (props.hasItems ? 'all' : 'none')};
    opacity: ${(props) => (props.hasItems ? 1 : 0.6)};
    cursor: ${(props) => (props.hasItems ? 'pointer' : 'default')};
`

export default Cart
