import React, { useState, useEffect, useRef } from 'react'
import { navigate, Link } from 'gatsby'
import styled, { css, createGlobalStyle } from 'styled-components'
import Swiper from 'react-id-swiper'
import 'swiper/swiper-bundle.css'
import { useMedia } from 'react-use'

import { media, useBreakpoint, isClient } from '../../styles/utils'
import { container, padding, hoverState, bgIcon } from '../../styles/global'

import sliderArrow from '../../assets/icons/slider-arrow.svg'
import sliderArrowMobile from '../../assets/icons/slider-arrow-mobile.svg'

const Slider = (props) => {
    const { slides, renderSlides } = props
    const [swiper, setSwiper] = useState(null)
    const swiperRef = useRef(null)
    const canLoop = slides && slides.length > 1
    const isPhone = useBreakpoint('phone')
    const arrowImage = isPhone ? sliderArrowMobile : sliderArrow

    const swiperParams = {
        loop: canLoop,
        effect: false,
        speed: 0,
        noSwiping: isPhone ? false : true,
    }

    const nextSlide = () => {
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            swiperRef.current.swiper.slideNext()
        }
    }

    const prevSlide = () => {
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            swiperRef.current.swiper.slidePrev()
        }
    }

    return (
        <SliderWrapper sliderArrow={arrowImage}>
            <PrevButton>
                <Icon onClick={prevSlide} src={arrowImage} />
            </PrevButton>
            <Swiper ref={swiperRef} {...swiperParams}>
                {renderSlides && renderSlides(slides)}
            </Swiper>
            <NextButton>
                <Icon onClick={nextSlide} src={arrowImage} />
            </NextButton>
        </SliderWrapper>
    )
}

const NextButton = styled.div``
const PrevButton = styled.div``
const Icon = styled.img`
    width: 100%;
    height: auto;
`

const SliderWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;

    ${NextButton},
    ${PrevButton} {
        flex: 1;
        display: flex;
        align-items: center;

        ${Icon} {
            cursor: pointer;
            outline: 0;
            min-width: 40px;
        }

        ${media.phone`
            margin-top: -70px;
        `}
    }

    ${PrevButton} {
        transform: rotate(180deg);
    }

    /* custom swiper styles */
    .swiper-container {
        width: 509px;
        max-width: 100%;
        margin: 0 50px;

        ${media.phone`
            margin: 0 10px;
        `}
    }
`

export default Slider
