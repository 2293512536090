import { css, createGlobalStyle } from 'styled-components';
import { media } from './utils';

import globalType from './type'
import globalColours from './colours';

const maxWidth = 1440;

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        background: white;
        color: black;
        font-family: 'Neue Haas', sans-serif;
    }
    
    a {
        text-decoration: none;
    }

    h1, h2, h3 {
        font-size: 1rem;
        font-weight: 400;
        margin: 0;
    }

    a, p, div {
        ${globalType.body};
        letter-spacing: 0.15px;
        font-weight: 400;
    }

    b, strong {
        font-weight: 500;
    }

    * {
        -webkit-overflow-scrolling: touch;
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box;
    }

    p {
        margin-bottom: 0;

        &:first-child {
            margin-top: 0;
        }
    }

    /* Next Wrapper */

    #__next {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
`

export const wrapper = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: 100%;
`


export const container = css`
    display: flex;
    flex-direction: column;
	width: 100%;
    /* max-width: ${maxWidth}px; */
`

export const hoverState = css`
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const padding = css`
    padding-left: 20px; 
    padding-right: 20px;
    box-sizing: border-box;

    ${media.tablet`
        padding-left: 10px;
        padding-right: 10px;
    `}
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

export const button = css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 14px 12px;
    cursor: pointer;
    user-select: none;
    ${hoverState}
    background-color: black;
    border: 1px solid black;
    border-radius: 57px;

    font-family: Courier;
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    color: white;

    ${media.phone`
        font-size: 13px;
        font-weight: 400;
    `}

    &:disabled,
    &[disabled] {
        opacity: 0.25;
        pointer-events: none;
    }
`

// Re-export colours & type

export const colours = globalColours
export const type = globalType