import React from 'react'
import Transition from "./src/transition"
import { GlobalStyles } from './src/styles/global';
import { PortalProvider } from "react-portal-hook";
import CartProvider from './src/components/Cart/CartProvider'
import { Header } from './src/components'

export default ({ element, props  }) => {
    return (
        <CartProvider>
            <GlobalStyles/>
            <PortalProvider>
                <Header/>
                {element}
            </PortalProvider>
        </CartProvider>
    )
}