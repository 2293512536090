import React from 'react'
import styled, { css } from 'styled-components'
import { container, padding } from '../../styles/global'
import { media } from '../../styles/utils'

const Grid = props => {
    const { items } = props

    const renderItems = () => {
        return items.map((item, i) => {
            return (
                <Item
                    className={'grid-item'}
                    key={i}
                >
                    {item}
                </Item>
            )
        })
    }

    return (
        <Items
            className={'grid-items'}
        >
            {items && renderItems()}
        </Items>
    )
}

const Item = styled.div``

const Items = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 80px;
    grid-row-gap: 108px;
    width: 100%;
    margin-top: 29px;
    margin-bottom: 90px;

    ${media.phone`
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        margin-top: 20px;
        margin-bottom: 56px;
    `}
`

export default Grid
